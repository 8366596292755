import { getDomainName } from '../globalApi/utils/utils';

const Footer = () => (
  <footer className="w-full bottom-0" style={{ backgroundColor: '#D8E2E5' }}>
    <div className="py-5 container mx-auto px-4 flex flex-col">
      <p className="text-xs">
        +49 (176) 839 341 32 {getDomainName()} (c) is a brand name of Vie Finance
        A.E.P.E.Y. S.A., licenced and regulated by HCMC (licence no.
        4/792/20.07.2017) and General Commercial Registry no: 122000301000. 4,
        28th Octovriou street, Maroussi 15124, Athens, Greece Disclaimer and
        Risk Warning
      </p>
      <p className="text-sm mt-5">
        © 2019-{new Date().getFullYear()} {getDomainName()}
      </p>
    </div>
  </footer>
);

export default Footer;
