import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowContext } from "../globalApi/context/sizeContetx";
import HeaderLaptop from "./HeaderLaptop";
import HeaderMobile from "./HeaderMobile";
import i18next from "i18next";
import { formatOptions } from "../globalApi/utils/formats";
import { getAuthToken } from "../globalApi/utils/cookie";

const Header = () => {
  const { t } = useTranslation();
  const { atLeastMd } = useWindowContext();
  const options = formatOptions(i18next.languages);
  const authToken = getAuthToken();

  if (atLeastMd) {
    return <HeaderLaptop options={options} isAuth={!!authToken} />;
  } else {
    return <HeaderMobile options={options} isAuth={!!authToken} />;
  }
};

export default Header;
