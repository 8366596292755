import React, { useState, useEffect, useMemo, memo, useRef } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { validate } from 'crypto-address-validator-ts';
import Select from 'react-select';

import ErrorMessage from './ui/ErrorMessage';
import { Currency, CurrencyFrom } from '../globalApi/types/pair';
import { useUpdateTransaction } from '../globalApi/apis/transactions';
import { Transaction, TransactionForm } from '../globalApi/types/transaction';
import { getCookies } from '../globalApi/utils/cookie';
import { useTranslation } from 'react-i18next';
import { isLocal } from '../globalApi/utils/utils';
import { getCurrencyName } from '../globalApi/utils/formats';
import { TransferFormProps, PairsProps } from '../globalApi/props/interfaces';
import { useGetExtraFormData } from '../globalApi/hooks/useGetExtraFormData';
import { useGetRate } from '../globalApi/hooks/useGetRate';
import { useGetAvailablePairs } from '../globalApi/hooks/useGetAvailablePairs';

type Props = TransferFormProps & PairsProps;

const TransferForm = ({
  selectedPair,
  toItem,
  fromItem,
  pairs,
  handleSelectFromItem,
  handleSelectToItem,
}: Props) => {
  const formikRef: React.Ref<FormikProps<TransactionForm>> | null =
    useRef(null);
  const { t } = useTranslation();

  const generalValidation = useMemo(
    () => ({
      email: Yup.string()
        .email(t('base.emailIsInvalid'))
        .required(t('base.required')),
      addressSending: Yup.string().required(t('base.required')),
      amountFrom: Yup.number()
        .typeError(t('base.mustBeANumber'))
        .required(t('base.required')),
      amountTo: Yup.number()
        .typeError(t('base.mustBeANumber'))
        .required(t('base.required')),
    }),
    [t]
  );

  const navigate = useNavigate();
  const [validation, setValidation] = useState(generalValidation);
  const updateTransactionMutation = useUpdateTransaction();

  const { percent, min, max } = useGetExtraFormData({
    selectedPair,
  });
  const { rate, fromItemPrice: amount, renderRate } = useGetRate({
    selectedPair,
    percent,
  });

  const { availableCurrenciesFrom, availableCurrenciesTo } = useGetAvailablePairs({
    pairs, fromItem,
  });

  const emailFromCookies = useMemo(() => getCookies('email') || '', []);

  useEffect(() => {
    if (formikRef.current) {
      const { setFieldValue, values } = formikRef.current;
      const newAmount = values.amountFrom * Number(rate);
      setFieldValue('amountTo', newAmount);
    }
  }, [rate]);

  useEffect(() => {
    setValidation((prevState) => ({
      ...prevState,
      amountFrom: Yup.number()
        .max(max, t('base.mustBeLessThanOrEqual', { value: max }))
        .min(min, t('base.mustBeGreaterThanOrEqual', { value: min }))
        .typeError('Must be a number')
        .required(t('base.required')),
      addressSending: Yup.string()
        .test('crypto-validate', 'Wallet is not valid', (value) => {
          if (isLocal()) return true;
          if (toItem?.network === 'TRC20' && value) {
            return /T[A-Za-z1-9]{33}/.test(value);
          }

          return validate(value as string, toItem?.symbol || '', {
            networkType: toItem?.network || toItem?.symbol || '',
            chainType: '',
          });
        })
        .required(t('base.required')),
    }));
    formikRef.current?.validateForm();
  }, [max, min, fromItem, toItem]);

  const initialValues: TransactionForm = {
    email: emailFromCookies,
    addressSending: '',
    amountFrom: min || 1,
    amountTo: 0,
  };

  const handleSubmit = async (values: TransactionForm) => {
    const data: Transaction = {
      ...values,
      currencyFromId: fromItem?.id || '',
      currencyToId: toItem?.id || '',
      status: 'created',
      amount: amount * Number(values.amountFrom),
      percent,
    };
    updateTransactionMutation.mutateAsync(
      { data },
      {
        onSuccess: (data) => navigate(`/qr/${data.data.model.id}`),
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape(validation)}
      enableReinitialize
      innerRef={formikRef}
    >
      {({ setFieldValue }) => (
        <Form className="grid grid-cols-1 gap-x-8 col-span-4 md:grid-cols-4">
          <div className="col-span-2 rounded-lg bg-white p-9 mb-5 md:mb-0">
            <p className="text-3xl	text-accent2 font-bold mb-9">
              {t('general.give')}
            </p>
            <Select
              className="mb-6"
              value={fromItem as Currency}
              onChange={(option) => handleSelectFromItem(option as CurrencyFrom)}
              options={availableCurrenciesFrom}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => getCurrencyName(option)}
              classNamePrefix="SelectField"
            />
            <div className="mb-3">
              <div className="relative">
                <Field
                  name="amountFrom"
                  placeholder={min}
                  className="TextField"
                  type="number"
                  min={0}
                  step="0.01"
                  onChange={(e: React.ChangeEvent<{ value: string }>) => {
                    setFieldValue('amountFrom', e.target.value);
                    setFieldValue(
                      'amountTo',
                      Number(e.target.value) * Number(rate)
                    );
                  }}
                />
                <span className="absolute right-0 top-[50%] -translate-y-[50%] bg-white">
                  {getCurrencyName(fromItem)}
                </span>
              </div>
              <ErrorMessage name="amountFrom" />
            </div>
            {min && max && (
              <span className="text-sm text-secondaryText">
                ({t('base.min')} - {min}, {t('base.max')} - {max})
              </span>
            )}
            <p className="text-sm text-secondaryText">
              {t('pairs.exchangeRate')}:{' '}
              <span className="block md:inline">{renderRate}</span>
            </p>
            <div className="mt-3">
              <Field
                disabled={!!emailFromCookies}
                name="email"
                placeholder="E-mail"
                className="TextField"
              />
              <ErrorMessage name="email" />
            </div>
          </div>
          <div className="col-span-2 rounded-lg bg-white p-9">
            <p className="text-3xl	text-accent2 font-bold mb-9">
              {t('general.take')}
            </p>
            <Select
              className="mb-6"
              value={toItem}
              onChange={(option) => handleSelectToItem(option as Currency)}
              options={availableCurrenciesTo}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => getCurrencyName(option)}
              isOptionDisabled={(option) => !option.isAvailable}
              classNamePrefix="SelectField"
            />
            <div className="my-3">
              <div className="relative">
                <Field
                  name="amountTo"
                  placeholder="400"
                  className="TextField"
                  type="number"
                  disabled
                />
                <span className="absolute right-0 top-[50%] -translate-y-[50%] bg-white">
                  {getCurrencyName(toItem)}
                </span>
              </div>
              <ErrorMessage name="amountTo" />
            </div>
            <div className="mb-3">
              <Field
                name="addressSending"
                placeholder={`${getCurrencyName(toItem)} ${t('pairs.wallet')}`}
                className="TextField"
              />
              <ErrorMessage name="addressSending" />
            </div>
            <button
              type="submit"
              className="rounded-lg mt-4 bg-black hover:bg-accent text-white w-full py-3 transition"
            >
              {t('pairs.proceedToCheckout')}
            </button>
            <p className="text-secondaryText text-center text-sm mt-4">
              {t('pairs.pushingTheButton')}{' '}
              <Link className="underline" to="/rules">
                {t('headerLinks.rules')}
              </Link>
              .
            </p>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo(TransferForm);
