import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { Option } from "../globalApi/types/global";
import Select from "react-select";
import { SettingsContext, SettingsContextType } from "../globalApi/context/settingsContext";

interface Props {
  options: Option[];
  isAuth: boolean;
}

const HeaderLaptop = ({ options, isAuth }: Props) => {
  const { i18n, t } = useTranslation();
  const { logo } = useContext(SettingsContext) as SettingsContextType;

  return (
    <header className="container mx-auto px-4 my-10">
      <div className="flex justify-between">
        <div className="flex">
          <Link to="/" className="h-14 w-full">
            <img className="h-full object-contain" src={logo} alt="logo" />
          </Link>
          <div className="ml-5 text-xs text-accent">
            <div className="flex items-center">
              <Icon name="autorenew" />
              <p className="ml-3 w-32">{t("general.automaticExchange")}</p>
            </div>
            <div className="flex items-center">
              <Icon name="local_convenience_store" />
              <p className="ml-3 w-32">{t("general.support")}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Link
            to="/login"
            className="flex ml-2 items-center px-3 rounded-lg bg-accent transition h-full text-sm text-white"
          >
            <Icon name="arrow_forward" className="mr-2" />
            {t(isAuth ? "personalCabinet" : "signIn")}
          </Link>
        </div>
      </div>
    </header>
  );
};

export default HeaderLaptop;
