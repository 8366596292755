import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import Select from 'react-select';
import { Option } from '../globalApi/types/global';
import {
  SettingsContext,
  SettingsContextType,
} from '../globalApi/context/settingsContext';
import { links } from './SideBarMenu';

interface Props {
  options: Option[];
  isAuth: boolean;
}

const HeaderMobile = ({ options, isAuth }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const { logo } = useContext(SettingsContext) as SettingsContextType;

  const handleOpen = () => {
    if (isOpen) {
      document.querySelector('[id="root"]')?.setAttribute('class', '');
    } else {
      document
        .querySelector('[id="root"]')
        ?.setAttribute('class', 'overflow-hidden	h-screen');
    }
    setIsOpen((prevState) => !prevState);
  };

  return (
    <header className="container mx-auto my-5 overflow-hidden">
      <div className="relative grid grid-cols-12 z-50">
        <Link to="/" className="h-14 col-span-4">
          <img className="h-full w-full object-contain" src={logo} alt="logo" />
        </Link>
        <div className="flex items-center col-span-8 justify-end">
          <Link
            to="/login"
            className="flex items-center p-2 rounded-lg bg-accent transition text-sm text-white"
          >
            <Icon name="arrow_forward" className="mr-2" />
            {t(isAuth ? 'personalCabinet' : 'signIn')}
          </Link>

          <button type="button" onClick={handleOpen} className="p-3">
            {isOpen ? <Icon name="close" /> : <Icon name="menu" />}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="absolute bg-white w-full h-full z-40 text-center left-0 p-5">
          <Select
            className="text-black w-[100px] m-auto"
            options={options}
            value={options.find(
              (option) => option.value === i18n.resolvedLanguage
            )}
            onChange={(option) => i18n.changeLanguage(option?.value)}
          />
          <nav className="flex flex-col">
            {links.map((link) => (
              <Link
                key={link.i18n}
                className="flex items-center hover:text-accent mb-8"
                to={link.url}
                onClick={handleOpen}
              >
                <Icon name={link.icon} />
                <p className="ml-4">{t(link.i18n)}</p>
              </Link>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default HeaderMobile;
