import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from './Icon';

export const links = [
  {
    i18n: 'headerLinks.change',
    url: '/',
    icon: 'currency_exchange',
  },
  {
    i18n: 'headerLinks.rules',
    url: '/rules',
    icon: 'menu_book',
  },
  {
    i18n: 'headerLinks.faq',
    url: '/faq',
    icon: 'quiz',
  },
  {
    i18n: 'headerLinks.contacts',
    url: '/contacts',
    icon: 'call',
  },
];

const SideBarMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="hidden md:block">
      {links.map((link) => (
        <Link
          to={link.url}
          key={link.url}
          className="flex items-center hover:text-accent mb-8"
        >
          <Icon name={link.icon} />
          <p className="ml-4">{t(link.i18n)}</p>
        </Link>
      ))}
    </div>
  );
};

export default SideBarMenu;
