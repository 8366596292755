import React, { memo, useEffect, useMemo, useContext } from 'react';
import { getResponseList } from '../globalApi/apis/utils';
import Loader from '../components/Loader';

import TechnicalProblem from '../components/TechnicalProblems';
import TransferForm from '../components/TransferForm';
import { ConfigContext } from '../globalApi/context/configContext';
import { useGetSelectedCurrencies } from '../globalApi/hooks/useGetSelectedCurrencies';
import { MainProps } from '../globalApi/props/interfaces';
import useGetSelectedPair from '../globalApi/hooks/useGetSelectedPair';
import { useGetUniquePairs } from '../globalApi/apis/pairs';

const Main = ({ clientId, selectedPairId, setSelectedPairId }: MainProps) => {
  const configCtx = useContext(ConfigContext);
  const { data, refetch, isLoading, isError } = useGetUniquePairs(clientId);
  const pairs = useMemo(() => getResponseList(data), [data]);

  useEffect(() => {
    if (!clientId || !configCtx) return;
    const timeout = 1000 * 60 * configCtx.config.refetchTime;
    const interval = setInterval(refetch, timeout);
    return () => clearInterval(interval);
  }, [clientId, configCtx, refetch]);

  const selectedPair = useGetSelectedPair({ selectedPairId, pairs });

  const { handleSelectFromItem, handleSelectToItem, toItem, fromItem } =
    useGetSelectedCurrencies({
      pairs,
      selectedPairId,
      setSelectedPairId,
    });

  if (isError) return <TechnicalProblem />;

  if (isLoading) return <Loader />;

  return (
    <TransferForm
      {...{
        pairs,
        handleSelectFromItem,
        handleSelectToItem,
        toItem,
        fromItem,
        selectedPair,
      }}
    />
  );
};

export default memo(Main);
